import React, { useState } from "react";
import { Layout, Row, Col } from "antd";
import {
  LinkedinOutlined,
  YoutubeOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import footerImage from "../assets/images/homepagefooter.svg"; // Replace with the correct path to your image
import {Link}  from "react-router-dom";

const { Footer } = Layout;

const HomePageFooter = () => {
const [isMobileView, setIsMobileView] = useState(window.innerWidth < 800);
  // Update mobile view state on window resize
  const handleResize = () => {
    setIsMobileView(window.innerWidth < 800);
  };

  // Add event listener for window resize
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScrollToTop = (event) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <Footer className="app-footer">
      <div className="footer-content">
        <div className="image-and-title-container">
          <div className="footer-image-container">
            <img
              src={footerImage}
              alt="Footer Image"
              className="footer-image"
            />
          </div>
          <div className="footer-text-container">
            <h2 className="title-text">SHANKH</h2>
            <p className="wealth-text">Abode of the Wealth</p>
          </div>
        </div>
        <div className="footer-title-container">
          <div className="footer-section">
            <h3 className="footer-title"><Link to="/about-us" onClick={handleScrollToTop}>About Us</Link></h3>
            <ul className="footer-links">
              <li>
                <Link to="/about-us#focus-area">Focus Area</Link>
              </li>
              <li>
                <Link to="/about-us#team">Team</Link>
              </li>
              <li>
                <Link to="/about-us#other-initiatives">Other Initiatives</Link>
              </li>
            </ul>
          </div>

          <div className="footer-section">
            <h3 className="footer-title"><Link to="/products" onClick={handleScrollToTop}>Products</Link></h3>
            <ul className="footer-links">
              <li>
                <Link to="/products#advanced-investments">Advanced Investments</Link>
              </li>
              <li>
                <Link to="/products#financial-advisory">Financial Advisory</Link>
              </li>
              <li>
                <Link to="/products#compliance-solution">Compliance Solution</Link>
              </li>
              <li>
                <Link to="/products#financial-literacy-courses">Financial Literacy Courses</Link>
              </li>
            </ul>
          </div>

          <div className="footer-section">
            {isMobileView && (<h3 className="footer-title"><Link to="/contact-us" onClick={handleScrollToTop}>Contact Us</Link></h3>)}
            {!isMobileView && (<h3 className="footer-title">More</h3>)}
            <ul className="footer-links">
              <li>
                <Link to="/contact-us" onClick={handleScrollToTop}>Contact Us</Link>
              </li>
              <li>
                <Link to="/career" onClick={handleScrollToTop}>Career</Link>
              </li>
            </ul>
            {!isMobileView && (
            <div className="footer-social-icons">
              <a href ="https://www.linkedin.com/company/infinitypool" target="_blank"><LinkedinOutlined/></a>
              <a href ="https://www.youtube.com/@shankhtech" target="_blank"><YoutubeOutlined /></a>
              <a href ="https://x.com/@Akshay_Kunkulol" target="_blank"><TwitterOutlined /></a>
            </div>)}
          </div>
          {isMobileView && (
            <div className="footer-social-icons">
              <a href ="https://www.linkedin.com/company/infinitypool" target="_blank"><LinkedinOutlined/></a>
              <a href ="https://www.youtube.com/@shankhtech" target="_blank"><YoutubeOutlined /></a>
              <a href ="https://x.com/@Akshay_Kunkulol" target="_blank"><TwitterOutlined /></a>
            </div>)}
        </div>
      </div>
    </Footer>
  );
};

export default HomePageFooter;
